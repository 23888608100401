/*
 * @Author: Vir
 * @Date: 2021-03-18 16:26:35
 * @Last Modified by: Vir
 * @Last Modified time: 2022-03-16 15:56:48
 */

import { SearchEngine } from './types';

// 搜索引擎模板数据
export const WebsiteEngineTemplete: SearchEngine = {
  _id: '',
  name: '',
  value: '',
  href: '',
  icon: '',
  isShow: true,
  isSelected: false,
  count: 0,
  sort: -1,
  classifyId: '',
  userId: '',
  isDefault: false,
};

export default [
  {
    _id: '3176606942b5445a913c099aeac9ddb0',
    name: '必应',
    value: 'bing',
    href: 'https://cn.bing.com/search?q=',
    sugurl:
      'https://api.bing.com/qsonhs.aspx?type=cb&q=#content#&cb=window.bing.sug',
    icon: './img/engineLogo/bing.ico',
    isShow: true,
    isSelected: true,
    count: 0,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 1,
  },
  {
    _id: '4de5457415f1429ea318f8b112a59a6c',
    name: '谷歌',
    value: 'google',
    href: 'https://www.google.com/search?q=',
    sugurl:
      'https://suggestqueries.google.com/complete/search?client=youtube&q=#content#&jsonp=window.google.ac.h',
    icon: './img/engineLogo/google.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 2,
  },
  {
    _id: '755551cec9054314a50e74ac9130c34b',
    name: '百度',
    value: 'baidu',
    href: 'https://www.baidu.com/s?wd=',
    sugurl: 'https://suggestion.baidu.com/su?wd=#content#&cb=window.baidu.sug',
    icon: './img/engineLogo/baidu.svg',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 3,
  },
  {
    _id: 'e9275210f149443b9d554a59861887c8',
    name: '搜狗',
    value: 'sougou',
    href: 'https://www.sogou.com/web?query=',
    sugurl: 'https://www.sogou.com/suggnew/ajajjson?type=web&key=#content#',
    icon: './img/engineLogo/sougou.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 4,
  },
  {
    _id: 'ee4b853b01cd4c9bb3fdcb814c13b298',
    name: '好搜',
    value: '好搜',
    href: 'https://www.so.com/s?ie=utf-8&fr=hao_360so&src=home_hao_360so&q=',
    sugurl:
      'https://sug.so.360.cn/suggest?encodein=utf-8&encodeout=utf-8&format=json&word=#content#&callback=window.so.sug',
    icon: './img/engineLogo/so360.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 5,
  },
  {
    _id: 'ace54031b5bb4b4693e4ffc4cc304199',
    name: '夸克',
    value: 'quark',
    href: 'https://quark.sm.cn/s?q=',
    sugurl:
      'https://quark.sm.cn/api/quark_sug?q=#content#&callback=window.quark.sug',
    icon: './img/engineLogo/quark.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: '6c77e19433a1416d851ef898e0db5707',
    sort: 7,
  },
  {
    _id: 'ed44b0d5d032486a9aa8ada351a3a079',
    name: '淘宝',
    value: 'taobao',
    href: 'https://s.taobao.com/search?q=',
    sugurl:
      'https://suggest.taobao.com/sug?code=utf-8&q=#content#&_ksTS=1579098382790_8&callback=jsonp9',
    icon: './img/engineLogo/taobao.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 7,
  },
  {
    _id: '3d6385859687456e8a886699f3a70996',
    name: '天猫',
    value: 'tmall',
    href: 'https://list.tmall.com/search_product.htm?q=',
    sugurl:
      'https://suggest.taobao.com/sug?code=utf-8&q=#content#&_ksTS=1579098382790_9&callback=jsonp10',
    icon: './img/engineLogo/tmall.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 7,
  },
  {
    _id: '70cbbcee83d4410d9887aa10995da05b',
    name: '京东',
    value: 'jd',
    href: 'https://search.jd.com/Search?keyword=',
    sugurl:
      'https://search.jd.com/sug?area=19_1601_1688_0&keyword=#content#&callback=jQuery110208972379058578898_1579098382790&_=1579098382791',
    icon: './img/engineLogo/jd.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 7,
  },
  {
    _id: '539bf705d9db4ecdb66a0893ae1fdfbd',
    name: '苏宁',
    value: 'suning',
    href: 'https://search.suning.com/emall/searchV1Product.do?keyword=',
    sugurl:
      'https://suggest.suning.com/asynSuggest/asynSuggestQuery.do?keyword=#content#&callback=jQuery110208972379058578898_1579098382790&_=1579098382791',
    icon: './img/engineLogo/suning.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 7,
  },
  {
    _id: '27ad3bf876cc415fbb8846522a7e9fd9',
    name: '亚马逊',
    value: 'amazon',
    href: 'https://www.amazon.cn/s/ref=nb_sb_noss?__mk_zh_CN=%E4%BA%9A%E9%A9%AC%E9%80%8A%E7%BD%91%E7%AB%99&url=search-alias%3Daps&field-keywords=',
    sugurl: '',
    icon: './img/engineLogo/amazon.ico',
    isShow: true,
    isSelected: false,
    count: 0,
    isDefault: true,
    classifyId: 'df75e91b979445d0943856b57da93226',
    sort: 7,
  },
] as SearchEngine[];
